<template>
  <div>
    <!-- Define the SVG mask using the logo -->
    <svg :width="0" :height="0" style="position: absolute;">
      <defs>
        <mask id="logo-mask">
          <image
            :href="logoSrc"
            :width="computedLogoWidth"
            :height="computedLogoHeight"
            preserveAspectRatio="none"
          />
        </mask>
      </defs>
    </svg>

    <div
      class="canvas-wrapper"
      :style="canvasWrapperStyle"
    >
      <canvas
        ref="blurCanvas"
        class="main-canvas"
        :width="computedLogoWidth"
        :height="computedLogoHeight"
      ></canvas>
    </div>

    <div
      class="logo_container"
      :style="logoContainerStyle"
    >
      <img
        :src="logoSrc"
        class="logo"
        :style="logoStyle"
        alt="Logo"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount, computed } from 'vue';
import html2canvas from 'html2canvas';
import logoSrc from '@/assets/svgs/media/g.svg?url';

export default {
  name: 'NavLogo',
  props: {
    captureElementId: {
      type: String,
      default: 'app',
    },
    logoWidth: {
      type: Number,
      default: 50,
    },
    logoHeight: {
      type: Number,
      default: 50,
    },
    blurAmount: {
      type: Number,
      default: 10,
    },
    top: {
      type: Number,
      default: 10,
    },
    left: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const blurCanvas = ref(null);
    let capturedCanvas = null;

    const computedLogoWidth = ref(props.logoWidth);
    const computedLogoHeight = ref(props.logoHeight);

    const updateLogoSize = () => {
      if (window.innerWidth < 960) {
        computedLogoWidth.value = 33;
        computedLogoHeight.value = 33;
      } else {
        computedLogoWidth.value = props.logoWidth;
        computedLogoHeight.value = props.logoHeight;
      }
    };

    const logoContainerStyle = computed(() => ({
      width: `${computedLogoWidth.value}px`,
      height: `${computedLogoHeight.value}px`,
    }));

    const canvasWrapperStyle = computed(() => ({
      width: `${computedLogoWidth.value}px`,
      height: `${computedLogoHeight.value}px`,
    }));

    const logoStyle = computed(() => ({
      width: `${computedLogoWidth.value}px`,
      height: `${computedLogoHeight.value}px`,
    }));

    // Function to temporarily change the viewport meta tag
    const adjustViewport = (newContent) => {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        if (newContent) {
          // Set new content
          viewportMeta.setAttribute('content', newContent);
        } else {
          // Restore original content
          viewportMeta.setAttribute('content', originalViewportContent);
        }
      }
    };

    let originalViewportContent = null;

    const captureDOM = async () => {
      return;
      const captureElement = document.getElementById(props.captureElementId);

      if (!captureElement) {
        console.error(`Element with id '${props.captureElementId}' not found.`);
        return;
      }

      // Temporarily change the viewport meta tag
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        originalViewportContent = viewportMeta.getAttribute('content');
        // Set the viewport to a desktop width, e.g., width=1440
        adjustViewport('width=1440');
        // Wait for the viewport to adjust
        await new Promise((resolve) => setTimeout(resolve, 300));
      }

      html2canvas(captureElement, { scale: 1 })
        .then((tempCanvas) => {
          capturedCanvas = tempCanvas;
          drawCapturedCanvas();
        })
        .catch((error) => {
          console.error('Error capturing content:', error);
        })
        .finally(async () => {
          // Restore the original viewport meta tag
          if (viewportMeta) {
            adjustViewport(originalViewportContent);
            // Wait for the viewport to adjust back
            await new Promise((resolve) => setTimeout(resolve, 300));
          }
        });
    };

    const drawCapturedCanvas = () => {
      if (!capturedCanvas || !blurCanvas.value) return;

      const ctx = blurCanvas.value.getContext('2d', {
        willReadFrequently: true,
      });

      if (!ctx) {
        console.error('Canvas getContext failed');
        return;
      }

      // Apply blur filter
      ctx.filter = `blur(${props.blurAmount}px) saturate(1.5) brightness(1.2)`;

      // Calculate offsets
      const offsetX = window.innerWidth * 0.1;
      const offsetY = window.innerHeight / 2 - 2.5; // Centered vertically
      const scrollY = window.scrollY;

      const captureHeight = 50; // Adjust as needed
      const sourceWidth = window.innerWidth * 0.8;
      const sourceHeight = captureHeight;

      // Clear the canvas before redrawing
      ctx.clearRect(0, 0, blurCanvas.value.width, blurCanvas.value.height);

      ctx.globalAlpha = 1.0;

      // Draw the captured content onto the canvas
      ctx.drawImage(
        capturedCanvas,
        offsetX,
        scrollY + offsetY,
        sourceWidth,
        sourceHeight,
        0,
        0,
        computedLogoWidth.value,
        computedLogoHeight.value
      );

      ctx.filter = 'none';
    };

    const handleScroll = () => {
      drawCapturedCanvas();
    };

    const handleResize = () => {
      updateLogoSize();
      captureDOM();
    };

    onMounted(() => {
      captureDOM();
      updateLogoSize();

      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    });

    return {
      blurCanvas,
      logoSrc,
      logoContainerStyle,
      canvasWrapperStyle,
      computedLogoWidth,
      computedLogoHeight,
      logoStyle,
    };
  },
};
</script>

<style scoped>
div {
  position: relative;
}

.canvas-wrapper {
  position: absolute;
  z-index: 1000;
  mask: url(#logo-mask);
  -webkit-mask: url(#logo-mask);
}

.main-canvas {
  display: block;
}

.canvas-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;
  mask: url(#logo-mask);
  -webkit-mask: url(#logo-mask);
}

.logo_container {
  position: absolute;
  z-index: 999;
}

.logo {
  position: relative;
  display: block;
}
</style>
