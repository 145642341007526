<template>
  <span
    id="announcer"
    ref="announcer"
    aria-live="assertive"
    :class="screenreaderOffscreen"
    role="status"
    tabindex="-1"
  >
    {{ announcement }}
  </span>
  <a
    id="skip-link"
    aria-label="Skip to Main Content"
    :class="skip"
    href="#main"
    @click.prevent="skipTo('#main')"
    @keypress.enter="skipTo('#main')"
  >
    Skip to Main Content
  </a>
  <div :class="$style.frame">
    <Banner></Banner>
    <MainNav @change-class="changeClass"></MainNav>
    <div ref="page" class="page">
      <main id="main" :aria-hidden="overlay" aria-label="Main Content" :inert="overlay" role="main">
        <IconLoading v-if="!dataLoaded" :class="spinner"></IconLoading>
        <router-view v-if="!isPreviewLogin" id="content" :key="$route.fullPath"></router-view>
      </main>
    </div>
  </div>
  <FooterNav :aria-hidden="overlay" :inert="overlay"></FooterNav>
  <div v-if="overlay" :class="$style.overlay" aria-hidden="true" @click="closeOverlay"></div>
  <AudioPlayer v-if="tracks.length"></AudioPlayer>
</template>

<script setup lang="ts">
import 'normalize.css';

import { AppConfig } from '@/types/global.interface';
import Banner from '@/components/common/Banner.vue';
import {
  computed,
  defineAsyncComponent,
  getCurrentInstance,
  inject,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  ref,
  watch,
} from 'vue';
import FooterNav from '@/components/navigation/FooterNav.vue';
import IconLoading from '@/assets/svgs/IconLoading.svg?component';
import MainNav from '@/components/navigation/MainNav.vue';
import { redirectUnsupportedBrowser } from '@/utils';
import { screenreaderOffscreen, skip } from '@/styles/a11y.module.scss';
import { spinner } from '@/styles/animations.module.scss';
import { storeToRefs } from 'pinia';
import { useA11y, useAnalytics, useScrollAnchor } from '@/composables/Common.js';
import { useAudioStore } from '@/stores/audioStore';
import { useMainStore } from '@/stores/mainStore';
import { useRoute, useRouter } from 'vue-router';

const appConfig = inject<AppConfig>('appConfig');
const AudioPlayer = defineAsyncComponent(() => import('@/components/audio/AudioPlayer.vue'));

const audioStore = useAudioStore();
const instance = getCurrentInstance();
const route = useRoute();
const router = useRouter();
const store = useMainStore();
const { announcement, dataLoaded, isKeyboardUser, overlay, savedPosition } = storeToRefs(store);
const { tracks } = storeToRefs(audioStore);

const { detectKeyboardUser, findFocusable, focusSkip } = useA11y();
const { initAnalytics } = useAnalytics();
const { scrollToAnchor } = useScrollAnchor();
const { setValue } = store;

const activeLinks = ref(null);
const announcer = ref(null);
const isPreviewLogin = ref(false);
const page = ref();

const scrollToName = (name) => {
  history.pushState({}, '', `#${name}`);

  const selector = `[name="_${name}"]`;

  scrollToAnchor(document.querySelector(selector));
};

const hasHash = computed(() => {
  if (route.hash) {
    const hash = route.hash;

    if (hash.substring(0, 2) === '#_') {
      // footnotes
      return `[name=${hash.substring(1)}]`;
    }
  }

  return '';
});

defineExpose({ scrollTo, scrollToName });

watch([dataLoaded, savedPosition], ([loaded, position]) => {
  if (position && loaded) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        window.scroll(savedPosition.value);
        setValue({ type: 'savedPosition', value: false });
      });
    });
  }

  if (loaded) {
    // Slight delay needed here to ensure capturing new content.
    nextTick().then(() => {
      initAnalytics();
      watchActiveLinks();
      detectKeyboardUser();
    });

    if (isKeyboardUser.value && !hasHash.value) {
      requestAnimationFrame(() => {
        requestAnimationFrame(focusSkip);
      });
    }
  }

  if (!loaded) {
    unWatchActiveLinks();
  }
});

isPreviewLogin.value = Boolean(route.query.preview && !appConfig.loggedIn);

onBeforeUnmount(() => {
  announcer.value.removeEventListener('focusin', preventFocus);
});

onMounted(() => {
  announcer.value.addEventListener('focusin', preventFocus);

  initAnalytics();
  watchActiveLinks();
  detectKeyboardUser();

  if (route.fullPath !== '/browser-not-supported') {
    redirectUnsupportedBrowser(router, document.title.split('|')[0].trim());
  }

  window.guggApp = instance;
});

onUpdated(() => {
  if (hasHash.value) {
    nextTick(() => scrollToAnchor(document.querySelector(hasHash.value)));
  }
});

function changeClass(action: string, name: string): void {
  switch (action) {
    case 'add':
      page.value.classList.add(name);
      break;
    case 'remove':
      page.value.classList.remove(name);
      break;
  }
}

function closeOverlay(): void {
  setValue({ type: 'overlay', value: false });
}

function preventFocus(event: Event): void {
  event.relatedTarget ? event.relatedTarget.focus() : event.target.blur();
}

// TODO: GUGG remove?
function scrollable(val): void {
  if (val) {
    document.body.classList.add('noscroll');
  }

  if (!val) {
    document.body.classList.remove('noscroll');
  }
}

function scrollTo(anchor: string): void {
  history.pushState({}, '', anchor);
  scrollToAnchor(document.querySelector(anchor));
}

function skipTo(anchor: string): void {
  anchor = route.hash || anchor;
  history.pushState({}, '', anchor);

  const target = document.querySelector(anchor);
  const firstFocusable = findFocusable(target)[0];

  firstFocusable.focus();
  window.scrollTo({
    top: firstFocusable.offsetTop - 100,
    behavior: 'smooth',
  });
}

function unfocusLink(event: Event): void {
  if (event.key !== 'Tab' || event.code !== 'Tab') {
    event.target.blur();
  }
}

function unWatchActiveLinks(): void {
  ['click', 'keydown'].map((type) => {
    if (activeLinks.value) {
      Array.from(activeLinks.value).forEach((link) => {
        link.removeEventListener(type, unfocusLink);
      });
    }
  });
}

function watchActiveLinks(): void {
  activeLinks.value = document.getElementsByClassName('router-link-exact-active');
  ['click', 'keydown'].map((type) => {
    if (activeLinks.value) {
      Array.from(activeLinks.value).forEach((link) => {
        link.addEventListener(type, unfocusLink);
      });
    }
  });
}
</script>

<style lang="scss">
@import '@/styles/typography.scss';
@import '@/styles/main.scss';
@import '@/styles/grid.scss';
@import '@/styles/buttons.scss';
@import '@/styles/lightbox.scss';
@import '@/styles/stacked.scss';
@import '@/styles/popup.scss';
</style>

<style lang="scss" module>
.frame {
  margin-top: px-to-rem(67);
}

.mobile {
  padding-bottom: px-to-rem(50);
  padding-top: px-to-rem(57.5);
}

.overlay {
  background-color: hsla(0, 0%, 97%, 0.9);
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: map-get($zindex, overlay);
}

@media (min-width: $break-point) {
  .frame {
    margin-top: 0;
  }

  :global(.page) {
    &:global(.fixed) {
      margin-top: 10.5rem;
    }
  }
}
</style>
