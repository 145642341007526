<template>
  <section v-if="exhibitions && exhibitions.length">
    <h2 class="heading-4" v-html="title"></h2>
    <ul v-if="exhibitions.length" :class="$style.list">
      <li v-for="(exhibition, key) in exhibitions" :key="key" class="space-22-27-above">
        <router-link
          :to="{
            name: 'ExhibitionsPost',
            params: { slug: exhibition.slug },
          }"
          :ga4-event='`{"click_type": "title", "component": "ExhibitionsOnView", "content_group": "exhibition", "gtm_tag": "link", "link_text": "${exhibition.title}", "link_url": "${exhibition.slug}"}`'
          @click="
            $emit('close');
            checkIsSamePath(exhibition.slug, emit);
          "
          @keydown.enter="
            $emit('close');
            checkIsSamePath(exhibition.slug, emit, $event);
          "
        >
          {{ exhibition.title }}
        </router-link>
        <span :class="$style.label" v-html="format(exhibition.dates)"></span>
      </li>
    </ul>
    <router-link
      :class="[$style.link, 'more-link']"
      :to="{ name: 'ExhibitionsPage' }"
      :ga4-event='`{"click_type": "link", "component": "ExhibitionsOnView", "content_group": "exhibition", "gtm_tag": "link", "link_text": "${link}", "link_url": "/exhibitions"}`'
      @click="
        $emit('close');
        checkIsSamePath('/exhibitions', emit);
      "
      @keydown.enter="
        $emit('close');
        checkIsSamePath('/exhibitions', emit, $event);
      "
    >
      {{ link }}
    </router-link>
  </section>
</template>

<script setup lang="ts">
import { Exhibition, ExhibitionDates } from '@/types/exhibition.interface';
import { exhibitionsOnViewQuery } from '@/queries/ExhibitionsOnViewQuery';
import gql from 'graphql-tag';
import { inject, ref } from 'vue';
import { MainMenu } from '@/types/global.interface';
import { storeToRefs } from 'pinia';
import { useHandleError, useNavigation } from '@/composables/Common.js';
import { useMainStore } from '@/stores/mainStore.js';
import { useQuery } from 'villus';

interface DateObject {
  day: string;
  month: string;
  year: string;
}

interface ExhibitionDates {
  label: string;
  end: DateObject;
  start: DateObject;
}

const emit = defineEmits({ close: null, isSamePath: false });
const props = withDefaults(
  defineProps<{
    limit?: string;
    link?: string;
    title?: string;
  }>(),
  {
    limit: '3',
    link: 'Browse all exhibitions',
    title: 'Exhibitions on View',
  }
);

const mainMenu = inject<MainMenu>('mainMenu');

const { checkIsSamePath } = useNavigation();

const store = useMainStore();
const { handleError } = useHandleError();

const { useBootstrap } = storeToRefs(store);

const exhibitions = ref<Array<Exhibition>>([]);
const shouldSkip = ref(true);

if (useBootstrap.value) {
  shouldSkip.value = true;
  exhibitions.value = mainMenu.exhibitions;
}

if (!useBootstrap.value) {
  shouldSkip.value = false;
}

const { data, error } = useQuery({
  onData: data => {
    if (data && Object.keys(data).length) {
      exhibitions.value = data.exhibitions.nodes;
    }

    if (!data || Object.keys(data).length === 0) {
      handleError('ExhibitionsOnView', error, false);
    }
  },
  query: gql`${exhibitionsOnViewQuery}`,
  skip: shouldSkip,
  variables: { first: +props.limit },
});

function format(dates: ExhibitionDates): string {
  if (dates.label) {
    return dates.label;
  }

  return `Through ${dates.end.month} ${dates.end.day}, ${dates.end.year}`;
}
</script>

<style lang="scss">
@import '@/styles/links.module.scss';
</style>

<style lang="scss" module>
.label {
  @include body-2;
  @include space-0-above;
  color: $black;
  display: block;
}
.link {
  &:global(.more-link) {
    @include space-22-27-above;
  }
}
.list {
  margin: 2rem 0 0;
  padding: 0;

  li {
    list-style: none;
  }

  a:not(:global(.more-link)) {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (min-width: $break-point) {
  .list {
    column-gap: $column-gutter-large;
    display: grid;
    grid-template-columns: repeat(v-bind('exhibitions.length'), 1fr);
  }
}
</style>
